import './FourOFour.css'

export default function FourOFour() {

  return (
    <div id="outer-404">
      <div id="gif-div">
        <img src="https://media.tenor.com/zSR_357T2IMAAAAM/hey-now.gif" style={{width: '220px', height: '228px'}}></img>
      </div>
      <div id="insight">LOOKS LIKE THIS PAGE DOESN'T EXIST!!</div>
      <div id="home-div-404"><a id="home-link" href='/'>Home</a></div>
    </div>
  )
}
